<template>
  <div>
    <EditCompany />
  </div>
</template>

<script>
import EditCompany from '@/components/Dashboard/Companies/EditCompany'
export default {
  components: {
    EditCompany,
  },
}
</script>

<style></style>
