<template>
  <Wrapper class="max-w-2xl">
    <div class="mb-3 text-left">
      <h2 class="text-xl"><b>Información de la entidad</b></h2>
      <p class="text-gray-500">Edite la información de la entidad</p>
    </div>
    <form @submit.prevent="updateCompany" class="mx-auto max-w-2xl">
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div class="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
          <BaseInput
            type="text"
            label="Nombre"
            placeholder="Ej: Empresa S.A"
            v-model="form.name"
          />
          <BaseInput
            type="text"
            label="Correo"
            v-model="form.email"
            placeholder="Ej: ejemplo@ejemplo.com"
          />
          <BaseInput
            type="text"
            label="Color primario (HEX)"
            v-model="form.primary_color"
            placeholder="#ffffff"
          />
          <BaseInput
            type="text"
            label="Color Secundario (HEX)"
            v-model="form.secondary_color"
            placeholder="#ffffff"
          />
          <BaseSelect
            ref="channel"
            id="channel"
            label="Sector compañía"
            :selectOptions="formOptions.optionsSectors"
            v-model="form.sector"
            :required="true"
          />
          <BaseRadio
            ref="generatedCode"
            name="generatedCode"
            label="¿Generar automáticamente el código de la queja?"
            :radioOptions="formOptions.optionsYN"
            v-model="form.generated_code"
          />
          <div class="col-span-2 text-left">
            <div>
              <p class="mb-2 text-gray-500 text-sm font-bold">Logo entidad</p>
              <ul class="mb-3 text-xs text-gray-500">
                <li class="mb-1">Requisitos recomendados:</li>
                <li>
                  Formato PNG - Dimensiones 1280x365 - Logo en color blanco
                </li>
              </ul>
            </div>

            <div v-if="logoUrl" class="flex flex-col gap-3">
              <div
                style="padding-top: 29.51%"
                class="w-full relative rounded-xl bg-gray-500 overflow-hidden"
              >
                <div class="absolute top-0 w-full h-full">
                  <img
                    id="companyLogo"
                    :src="logoUrl"
                    class="w-full h-full"
                    alt=""
                  />
                </div>
              </div>
              <span
                v-if="logo"
                class="inline-block p-2 rounded-lg border border-gray-300"
              >
                {{ logo.name || 'logo.png' }}
              </span>
              <Button :danger="true" @click.native="deleteLogoFile"
                ><a-icon type="delete"></a-icon> Eliminar
              </Button>
            </div>
            <div v-else>
              <label for="logo-input">
                <span
                  class="block text-center px-3 py-2 bg-secondary rounded-lg text-white font-bold cursor-pointer hover:bg-gray-400"
                  >Cargar archivo</span
                >
                <input
                  type="file"
                  ref="file"
                  id="logo-input"
                  accept=".png"
                  class="hidden"
                  @change="setLogoFile"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="col-span-2 text-left">
          <h2 class="mt-5 text-xl">
            <b>Información conexión con SFC</b>
          </h2>
          <p class="text-gray-500">
            Edite la información requerida para la conexión con la
            Superintendencia financiera
          </p>
        </div>
        <div class="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4">
          <BasePassword label="Contraseña" v-model="form.password_sfc" />
          <BasePassword label="Key SFC" v-model="form.secret_key" />
          <BaseInput type="text" label="Código SFC" v-model="form.sfc_code" />
          <BaseInput
            type="text"
            label="Tipo SFC"
            v-model="form.sfc_company_type"
          />
        </div>
      </div>
      <div v-if="feedback">
        <p class="mt-8 text-xs text-center text-red-500">{{ feedback }}</p>
      </div>
      <div
        class="w-full mt-8 mr-auto flex flex-col lg:flex-row-reverse lg:justify-end gap-4"
      >
        <Button
          type="submit"
          :block="true"
          :loading="loading"
          :disable="loading"
        >
          Guardar
        </Button>
        <Button
          type="button"
          @click.native="$router.go(-1)"
          :danger="true"
          :invert="true"
          :block="true"
          :disable="loading"
        >
          Cancelar
        </Button>
      </div>
    </form>
  </Wrapper>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      feedback: null,
      id: '',
      form: {},
      logo: null,
      logoUrl: '',
    }
  },
  computed: {
    ...mapState({ formOptions: 'form' }),
  },
  mounted() {
    const { company_id } = this.$route.params
    if (company_id) {
      this.id = company_id
      this.getCompany()
    } else {
      this.$router.go(-1)
    }
  },
  watch: {
    logo(value) {
      if (!value) return
      const reader = new FileReader()
      reader.onload = (e) => {
        this.logoUrl = e.target.result
      }
      reader.readAsDataURL(value)
    },
  },
  methods: {
    async getCompany() {
      this.loading = true
      const response = await this.$api.companies.getCompany(this.id)
      this.loading = false

      if (response.status !== 200) {
        this.$showNotification(
          'error',
          'Error consultando los datos de la entidad.'
        )
        return
      }
      this.form = { ...response.data }
      const url = await this.$getFirebaseLink(response.data.logo)
      this.logoUrl = url
    },
    async updateCompany() {
      if (this.invalidForm()) return

      let formData = new FormData()
      Object.entries(this.form).forEach((entry) => {
        if (entry[0] === 'logo' && !this.logoUrl) {
          formData.append(entry[0], '')
          return
        }
        formData.append(entry[0], entry[1])
      })

      formData.append('file', this.logo)

      this.loading = true
      let response = await this.$api.companies.updateCompany(this.id, formData)
      this.loading = false

      if (response.status !== 200) {
        this.$showNotification(
          'error',
          'Error actualizando los datos de la entidad'
        )
        return
      }

      this.$showNotification('success', 'Entidad actualizada correctamente.')
      this.$router.go(-1)
    },
    setLogoFile() {
      this.logo = this.$refs.file.files[0]
    },
    deleteLogoFile() {
      this.logo = null
      this.logoUrl = ''
    },
    invalidForm() {
      this.feedback = null

      if (this.$validator.isEmpty(this.form.name)) {
        this.feedback = 'Introduzca el nombre completo de la entidad'
        return true
      }
      if (this.$validator.isEmpty(this.form.email)) {
        this.feedback = 'Introduzca un usuario/correo'
        return true
      }
      if (
        this.form.primary_color &&
        !this.$validator.isHexColor(this.form.primary_color)
      ) {
        this.feedback =
          'El color primario debe contener un color hexadecimal válido'
        return true
      }
      if (
        this.form.secondary_color &&
        !this.$validator.isHexColor(this.form.secondary_color)
      ) {
        this.feedback =
          'El color secundario debe contener un color hexadecimal válido'
        return true
      }
      if (!this.form.sector || this.$validator.isEmpty(this.form.sector)) {
        this.feedback = 'Agregue el sector de la compañia'
        return true
      }
      if (this.$validator.isEmpty(this.form.password_sfc)) {
        this.feedback = 'Introduzca una contraseña'
        return true
      }
      if (this.$validator.isEmpty(this.form.secret_key)) {
        this.feedback = 'Introduzca la llave para conectarse a la SFC'
        return true
      }
      if (this.$validator.isEmpty(this.form.sfc_code)) {
        this.feedback = 'Introduzca el código de la entidad ante la SFC'
        return true
      }
      if (this.$validator.isEmpty(this.form.sfc_company_type)) {
        this.feedback = 'Introduzca el tipo de entidad ante la SFC'
        return true
      }

      return false
    },
  },
}
</script>

<style></style>
